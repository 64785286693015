<template>
   <div class="home home--static">
    
    <section class="section" style="padding: 0;">
        <div class="container" style="max-width:100%"> 
            <div class="row">
               <div class="col-12" dir="ltr">
                <div class="demo-cont">
  <!-- slider start -->
  <div class="fnc-slider example-slider">
    <div class="fnc-slider__slides">
      <!-- slide start -->
      <div class="fnc-slide m--blend-blue m--active-slide">
        <div class="fnc-slide__inner">
          <img class="fnc-slide__slideimage" src="../../assets/Ne3ma.png"/>
          <div class="fnc-slide__mask">
            <div class="fnc-slide__mask-inner"></div>
          </div>
          <div class="fnc-slide__content">
            <h2 class="fnc-slide__heading">
              <div class="fnc-slide__heading-line">
                <span>العلاج</span>
              </div>
              <div class="fnc-slide__heading-line">
                <span>الطبيعي</span>
              </div>
            </h2>
            <router-link type="button" :to="{name:'Category',params:{id:1}}" class="fnc-slide__action-btn">
              زيارة
              <span data-text="عـرض">زيارة</span>
            </router-link>
          </div>
        </div>
      </div>
      <!-- slide end -->
     
      <!-- slide start -->
      <div class="fnc-slide m--blend-blue">
        <div class="fnc-slide__inner">
          <img class="fnc-slide__slideimage" src="../../assets/heba.png"/>
          <div class="fnc-slide__mask">
            <div class="fnc-slide__mask-inner"></div>
          </div>
          <div class="fnc-slide__content">
            <h2 class="fnc-slide__heading">
              <div class="fnc-slide__heading-line">
                <span>الطب</span>
              </div>
              <div class="fnc-slide__heading-line">
                <span>النفسي</span>
              </div>
            </h2>
            <router-link type="button" :to="{name:'Category',params:{id:2}}" class="fnc-slide__action-btn">
              زيارة
              <span data-text="عـرض">زيارة</span>
            </router-link>
          </div>
        </div>
      </div>
      <!-- slide end -->
    </div>
    <nav class="fnc-nav">
      <div class="fnc-nav__bgs">
        <div class="fnc-nav__bg m--navbg-blue m--active-nav-bg"></div>
        
        <div class="fnc-nav__bg m--navbg-blue"></div>
      </div>
      <div class="fnc-nav__controls">
        <button class="fnc-nav__control">
          العلاج الطبيعي 
          <span class="fnc-nav__control-progress"></span>
        </button>
        <button class="fnc-nav__control">
          الطب النفسي 
          <span class="fnc-nav__control-progress"></span>
        </button>
        
      </div>
    </nav>
  </div>
  <!-- slider end -->
  
</div>

       



                   <!-- <div class="section__carousel-wrap">
                      <vueper-slides 
                          autoplay
                          rtl
                          class="no-shadow"
                          :visible-slides="4"
                          slide-multiple
                          :gap="3"
                          :slide-ratio="1 / 5"
                          :dragging-distance="200"
                          :breakpoints="{ 800: { visibleSlides: 1, slideMultiple: 1 } }">
                          <vueper-slide 
                              class="card_carousel shotal" 
                              v-for="publish in All.slice(0,8)" :key="publish.id" :image="publish.carsoul_img" :title="publish.title_ar" @click="getFeed(publish)" />
                          <vueper-slide class="card_carousel shotal" v-for="publish in All.slice(8,12)" :key="publish.id" :image="publish.carsoul_img" :title="publish.title_ar" @click="getFeed(publish)" />
                      </vueper-slides>
                   </div> -->
               </div>
            </div>
        </div>
    </section>
  </div>
  <section class="section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="col-12 col-sm-12 col-lg-12 col-xl-12 card__title" style="width: 100%;text-align: start;color: #0E574E;font-family: 'Cairo' ,sans-serif';margin-bottom: 10px;">
                            الأقسام الرئيسية
                          </div>
        </div>
        <div class="col-12">

          <div class="catalog__nav">
            <div class="col-6 col-sm-6 col-lg-6 col-xl-6" style="margin-top: 10px;">
              <div class="ms_rcnt_box marger_bottom30 pointer" style="margin-bottom: 10px;">
                <div class="ms_rcnt_box_img margin-zero card_category"><router-link :to="{name:'Category',params:{id:1}}" class=""><img
                      src="../../assets/tabi3e.png" alt="" class="img-fluid"></router-link>
                  <div class="ms_main_overlay">
                    <div class="ms_box_overlay"></div>
                  </div>
                </div>
                <div style="z-index: 999; display: block; position: relative;">
                  <h3><router-link :to="{name:'Category',params:{id:1}}"  class="text_size"
                      style="color: rgb(255, 255, 255); font-weight: 600; font-size: 15px;"></router-link></h3>
                </div>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-lg-6 col-xl-6" style="margin-top: 10px;">
              <div class="ms_rcnt_box marger_bottom30 pointer" style="margin-bottom: 10px;">
                <div class="ms_rcnt_box_img margin-zero card_category"><router-link :to="{name:'Category',params:{id:2}}"  class=""><img
                      src="../../assets/nafse.png" alt="" class="img-fluid"></router-link>
                  <div class="ms_main_overlay">
                    <div class="ms_box_overlay"></div>
                  </div>
                </div>
                <div style="z-index: 999; display: block; position: relative;">
                  <h3><router-link :to="{name:'Category',params:{id:2}}"  class="text_size"
                      style="color: rgb(255, 255, 255); font-weight: 600; font-size: 15px;"></router-link></h3>
                </div>
              </div>
            </div>
  
  
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <div class="pc-tab">
    <input checked="checked" id="tab0" type="radio" name="pct" />
    <input id="tab1" type="radio" name="pct" />
    <input id="tab2" type="radio" name="pct" />
    <input id="tab3" type="radio" name="pct" />
    <input id="tab4" type="radio" name="pct" />
    <input id="tab5" type="radio" name="pct" />
    <input id="tab6" type="radio" name="pct" />
    <nav>

      <!-- <ul  class="slider-radio">
        <li class="tab0">
          <label  for="tab0">الكل</label>
        </li>
        <li class="tab1">
          <label for="tab1"> العلاج الطبيعي</label>
        </li>
        <li class="tab2">
          <label for="tab2">الطب النفسي</label>
        </li>
        
       
        
       
      </ul> -->
    </nav>
    <section style="top: 5%;margin-top: 1vh;">
        <div class="tab0">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="row row--grid" style="margin-right: 5px;margin-left: 5px;">
                          <div class="col-12 col-sm-12 col-lg-12 col-xl-12 card__title" style="width: 100%;text-align: start;color: #0E574E;font-family:'Cairo' ,sans-serif;margin-bottom: 10px;">
                           أضيفت موخراً - العلاج الطبيعي
                          </div>
                          <div class="col-6 col-sm-4 col-lg-3 col-xl-2" v-for="publish in new_content_one.slice(0,6)" :key="publish.id">
                              <div id="busicard" @click="getFeed(publish)">
                                  <div class="menu">
                                      <div class="movembar">
                                          <h4 class="texto">
                                            <svg class="svg-icon" style="width: 20px;height:20px;margin-left:5px" viewBox="0 0 20 20">
                                              <path fill="#fff"
                                                d="M11.088,2.542c0.063-0.146,0.103-0.306,0.103-0.476c0-0.657-0.534-1.19-1.19-1.19c-0.657,0-1.19,0.533-1.19,1.19c0,0.17,0.038,0.33,0.102,0.476c-4.085,0.535-7.243,4.021-7.243,8.252c0,4.601,3.73,8.332,8.332,8.332c4.601,0,8.331-3.73,8.331-8.332C18.331,6.562,15.173,3.076,11.088,2.542z M10,1.669c0.219,0,0.396,0.177,0.396,0.396S10.219,2.462,10,2.462c-0.22,0-0.397-0.177-0.397-0.396S9.78,1.669,10,1.669z M10,18.332c-4.163,0-7.538-3.375-7.538-7.539c0-4.163,3.375-7.538,7.538-7.538c4.162,0,7.538,3.375,7.538,7.538C17.538,14.957,14.162,18.332,10,18.332z M10.386,9.26c0.002-0.018,0.011-0.034,0.011-0.053V5.24c0-0.219-0.177-0.396-0.396-0.396c-0.22,0-0.397,0.177-0.397,0.396v3.967c0,0.019,0.008,0.035,0.011,0.053c-0.689,0.173-1.201,0.792-1.201,1.534c0,0.324,0.098,0.625,0.264,0.875c-0.079,0.014-0.155,0.043-0.216,0.104l-2.244,2.244c-0.155,0.154-0.155,0.406,0,0.561s0.406,0.154,0.561,0l2.244-2.242c0.061-0.062,0.091-0.139,0.104-0.217c0.251,0.166,0.551,0.264,0.875,0.264c0.876,0,1.587-0.711,1.587-1.587C11.587,10.052,11.075,9.433,10.386,9.26z M10,11.586c-0.438,0-0.793-0.354-0.793-0.792c0-0.438,0.355-0.792,0.793-0.792c0.438,0,0.793,0.355,0.793,0.792C10.793,11.232,10.438,11.586,10,11.586z">
                                              </path>
                                            </svg>
                                            {{ publish.file_duration }}
                                          </h4>
                                      </div>
                                  </div>
                                  <div class="user">
                                      <img class="img2 pointer" v-lazy="publish.img">
                                  </div>
                                  <div class="bfollow pointer">
                                      <a @click="getFeed(publish)">مشاهدة</a>
                                  </div>
                                  <div class="content pointer" @click="getFeed(publish)">
                                      <p class="one-line-text">{{ publish.title_ar }}</p>
                                      <h6 style="margin-top: -1vh;font-size: 85%;"> {{ publish.sub_cat_name }} </h6>
                                  </div>
                              </div>
                          </div>
                          <div class="col-12 col-sm-12 col-lg-12 col-xl-12 card__title" style="width: 100%;text-align: start;color: #0E574E;font-family:'Cairo' ,sans-serif;margin-bottom: 10px;margin-top: 4vh;">
                            أضيفت موخراً - الطب النفسي
                          </div>
                          <div class="col-6 col-sm-4 col-lg-3 col-xl-2" v-for="publish in new_content_two.slice(0,6)" :key="publish.id">
                              <div id="busicard" @click="getFeed(publish)">
                                  <div class="menu">
                                      <div class="movembar">
                                          <h4 class="texto">
                                            <svg class="svg-icon" style="width: 20px;height:20px;margin-left:5px" viewBox="0 0 20 20">
                                              <path fill="#fff"
                                                d="M11.088,2.542c0.063-0.146,0.103-0.306,0.103-0.476c0-0.657-0.534-1.19-1.19-1.19c-0.657,0-1.19,0.533-1.19,1.19c0,0.17,0.038,0.33,0.102,0.476c-4.085,0.535-7.243,4.021-7.243,8.252c0,4.601,3.73,8.332,8.332,8.332c4.601,0,8.331-3.73,8.331-8.332C18.331,6.562,15.173,3.076,11.088,2.542z M10,1.669c0.219,0,0.396,0.177,0.396,0.396S10.219,2.462,10,2.462c-0.22,0-0.397-0.177-0.397-0.396S9.78,1.669,10,1.669z M10,18.332c-4.163,0-7.538-3.375-7.538-7.539c0-4.163,3.375-7.538,7.538-7.538c4.162,0,7.538,3.375,7.538,7.538C17.538,14.957,14.162,18.332,10,18.332z M10.386,9.26c0.002-0.018,0.011-0.034,0.011-0.053V5.24c0-0.219-0.177-0.396-0.396-0.396c-0.22,0-0.397,0.177-0.397,0.396v3.967c0,0.019,0.008,0.035,0.011,0.053c-0.689,0.173-1.201,0.792-1.201,1.534c0,0.324,0.098,0.625,0.264,0.875c-0.079,0.014-0.155,0.043-0.216,0.104l-2.244,2.244c-0.155,0.154-0.155,0.406,0,0.561s0.406,0.154,0.561,0l2.244-2.242c0.061-0.062,0.091-0.139,0.104-0.217c0.251,0.166,0.551,0.264,0.875,0.264c0.876,0,1.587-0.711,1.587-1.587C11.587,10.052,11.075,9.433,10.386,9.26z M10,11.586c-0.438,0-0.793-0.354-0.793-0.792c0-0.438,0.355-0.792,0.793-0.792c0.438,0,0.793,0.355,0.793,0.792C10.793,11.232,10.438,11.586,10,11.586z">
                                              </path>
                                            </svg>
                                            {{ publish.file_duration }}
                                          </h4>
                                      </div>
                                  </div>
                                  <div class="user">
                                      <img class="img2 pointer" v-lazy="publish.img">
                                  </div>
                                  <div class="bfollow pointer">
                                      <a @click="getFeed(publish)">مشاهدة</a>
                                  </div>
                                  <div class="content pointer" @click="getFeed(publish)">
                                      <p class="one-line-text">{{ publish.title_ar }}</p>
                                      <h6 style="margin-top: -1vh;font-size: 85%;"> {{ publish.sub_cat_name }} </h6>
                                  </div>
                              </div>
                          </div>
                          <div class="col-12 col-sm-12 col-lg-12 col-xl-12 card__title" style="width: 100%;text-align: start;color: #0E574E;font-family: 'Cairo' ,sans-serif;margin-bottom: 10px;margin-top: 4vh;">
                            الأعلي مشاهدة
                          </div>
                                <div class="col-6 col-sm-4 col-lg-3 col-xl-2" v-for="publish in TopViewed.slice(0,12)" :key="publish.id">
                                    <div id="busicard">
                                      <div class="menu">
                                        <div class="movembar">
                                          <h4 class="texto"><svg class="svg-icon"
                                          style="width: 20px;height:20px;margin-left:5px" viewBox="0 0 20 20">
                                          <path fill="#fff"
                                            d="M11.088,2.542c0.063-0.146,0.103-0.306,0.103-0.476c0-0.657-0.534-1.19-1.19-1.19c-0.657,0-1.19,0.533-1.19,1.19c0,0.17,0.038,0.33,0.102,0.476c-4.085,0.535-7.243,4.021-7.243,8.252c0,4.601,3.73,8.332,8.332,8.332c4.601,0,8.331-3.73,8.331-8.332C18.331,6.562,15.173,3.076,11.088,2.542z M10,1.669c0.219,0,0.396,0.177,0.396,0.396S10.219,2.462,10,2.462c-0.22,0-0.397-0.177-0.397-0.396S9.78,1.669,10,1.669z M10,18.332c-4.163,0-7.538-3.375-7.538-7.539c0-4.163,3.375-7.538,7.538-7.538c4.162,0,7.538,3.375,7.538,7.538C17.538,14.957,14.162,18.332,10,18.332z M10.386,9.26c0.002-0.018,0.011-0.034,0.011-0.053V5.24c0-0.219-0.177-0.396-0.396-0.396c-0.22,0-0.397,0.177-0.397,0.396v3.967c0,0.019,0.008,0.035,0.011,0.053c-0.689,0.173-1.201,0.792-1.201,1.534c0,0.324,0.098,0.625,0.264,0.875c-0.079,0.014-0.155,0.043-0.216,0.104l-2.244,2.244c-0.155,0.154-0.155,0.406,0,0.561s0.406,0.154,0.561,0l2.244-2.242c0.061-0.062,0.091-0.139,0.104-0.217c0.251,0.166,0.551,0.264,0.875,0.264c0.876,0,1.587-0.711,1.587-1.587C11.587,10.052,11.075,9.433,10.386,9.26z M10,11.586c-0.438,0-0.793-0.354-0.793-0.792c0-0.438,0.355-0.792,0.793-0.792c0.438,0,0.793,0.355,0.793,0.792C10.793,11.232,10.438,11.586,10,11.586z">
                                          </path>
                                        </svg>{{ publish.file_duration }} </h4>
                                          <!-- <a href="#" class="mbut"><span class="card__rating">
                                                                                  {{ publish.file_duration }}
                                                                                </span></a> -->
                                        </div>
                                        <!-- <div class="movegear">
                                            <a href="#"><div class="gear"></div></a>
                                        </div> -->
                                      </div>
                                      <div class="user pointer" @click="getFeed(publish)">
                                        <img class="img2" v-lazy="publish.img">
                                      </div>
                                     
                                    
                                      <div class="bfollow pointer">
                                        <a @click="getFeed(publish)">مشاهدة</a>
                                      </div>
                                      <div class="content pointer" @click="getFeed(publish)">
                                        <p class="one-line-text">{{ publish.title_ar }}</p>
                                        <h6 style="margin-top: -1vh;font-size: 85%;"> {{ publish.sub_cat_name }} </h6>
                                      </div>
                                    </div>

                                  
                                </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
  </div>
  <!-- end catalog -->       
</template>

<script>
import { HTTPSPAY } from '@/Api/http-spay';
import { HTTP } from '@/Api/http-common';
import { ref } from '@vue/reactivity';
import { useRouter } from "vue-router"
import { useCookie } from 'vue-cookie-next'
import { useToast } from "vue-toastification";
import 'vueperslides/dist/vueperslides.css';
import { watchEffect } from '@vue/runtime-core';
export default {
  name: 'HomePage',
  async setup() {
    const router = useRouter();
    const cookie = useCookie();
    const toast = useToast();
    const new_content_one = ref([]);
    const new_content_two = ref([]);
    const TopViewed = ref([]); 

    watchEffect(() => {
            if (!cookie.isCookieAvailable("msisdn") && !cookie.isCookieAvailable("status")) {
            let urlParams = new URLSearchParams(window.location.search);
            if(urlParams.get('phoneNumber') != null) {
                 if(urlParams.get('phoneNumber').length === 12) {
              if(urlParams.has('phoneNumber')) {
                HTTPSPAY.post(`CheckLogin.php?username=`+urlParams.get(`phoneNumber`) +`&serviceId=2222&operatorID=2`).then((res) => {
                    if (res.data.status == 1 ) {
                        cookie.setCookie('msisdn', urlParams.get('phoneNumber'), { expire: 60 * res.data.MINTUES, })
                        cookie.setCookie('status', res.data.status, { expire: 60 * res.data.MINTUES, })
                        cookie.setCookie('MINTUES', res.data.MINTUES, { expire: 60 * res.data.MINTUES, })
                        toast.success("تم تسجيل دخولك", { timeout: 2500 });
                    } else if (res.data.status == 0 && res.data.responseCode == 118) {
                        cookie.setCookie("msisdn", `2491`+urlParams.get('phoneNumber'), { expire: 60 * 120, });
                        toast.info("عفوا انت مشترك مسبقا ولم يتم تجديد اشتراكك", { timeout: 2500, });
                    } else {
                        toast.warning("عفوا ليست لديك اشترك في صحتك" , { timeout: 2500 });
                    }
                    setTimeout(() => router.push({ path: "/" }), 2500);
                    // if(cookie.getCookie("Content_id")!=null){
                    //     toast.info("سوف يتم تحويلك الي المحتوي", { timeout: 2000 });
                    //     setTimeout(() =>  router.push({name: "Content", params: { id: cookie.getCookie("Content_id") } }), 2000);
                    // } else {
                    //     setTimeout(() => router.push({ path: "/" }), 2500);
                    // }
                })
            }
            } else {
              if(urlParams.has('phoneNumber')) {
                HTTPSPAY.post(`CheckLogin.php?username=2491`+urlParams.get(`phoneNumber`) +`&serviceId=2222&operatorID=2`).then((res) => {
                    if (res.data.status == 1 ) {
                        cookie.setCookie('msisdn', `2491`+urlParams.get('phoneNumber'), { expire: 60 * res.data.MINTUES, })
                        cookie.setCookie('status', res.data.status, { expire: 60 * res.data.MINTUES, })
                        cookie.setCookie('MINTUES', res.data.MINTUES, { expire: 60 * res.data.MINTUES, })
                        toast.success("تم تسجيل دخولك", { timeout: 2500 });
                    } else if (res.data.status == 0 && res.data.responseCode == 118) {
                        cookie.setCookie("msisdn", `2491`+urlParams.get('phoneNumber'), { expire: 60 * 120, });
                        toast.info("عفوا انت مشترك مسبقا ولم يتم تجديد اشتراكك", { timeout: 2500, });
                    } else {
                        toast.warning("عفوا ليست لديك اشترك في صحتك" , { timeout: 2500 });
                    }
                    setTimeout(() => router.push({ path: "/" }), 2500);
                    // if(cookie.getCookie("Content_id")!=null){
                    //     toast.info("سوف يتم تحويلك الي المحتوي", { timeout: 2000 });
                    //     setTimeout(() =>  router.push({name: "Content", params: { id: cookie.getCookie("Content_id") } }), 2000);
                    // } else {
                    //     setTimeout(() => router.push({ path: "/" }), 2500);
                    // }
                })
            }
            }
            }
            
          } else {
              HTTPSPAY.post(`CheckLogin.php?username=`+cookie.getCookie("msisdn")+`&serviceId=2222&operatorID=2`).then((res) => {
                if (res.data.status === 0 ) {
                    this.$cookie.keys().forEach(cookie => this.$cookie.removeCookie(cookie))
                }
              })
          }
        });

        
    try {
      await HTTP.get(`GetAllContent.php?Content&catID=1&LIMIT=12&OFFSET=0`).then((res) => {
        new_content_one.value = res.data.Content; 
      });
    } catch (err) {
      console.log(err);
    }

    try {
      await HTTP.get(`GetAllContent.php?Content&catID=2&LIMIT=12&OFFSET=0`).then((res) => {
        new_content_two.value = res.data.Content; 
      });
    } catch (err) {
      console.log(err);
    }

    try {
      await HTTP.get(`GetMostViewed.php?LIMIT=6`).then((res) => {
        TopViewed.value = res.data.MostViewed; 
      });
    } catch (err) {
      console.log(err);
    }

    const getFeed = (publish) => {
        cookie.setCookie('Content_id', publish.id)
        cookie.setCookie('cat_id', publish.cat_id);
        router.push({ name: "Content", params: { Content_id: publish.id } });
    };

    return { new_content_one, new_content_two, TopViewed, getFeed};
    
  },
  mounted() {
    let externalScript = document.createElement("script");
    externalScript.setAttribute("src", "/assets/js/slider.js");
    document.head.appendChild(externalScript);
  },
 
}
</script>

<style>
.card_carousel {
  position: absolute;
width: 95%;
height: 95%;
background-image: linear-gradient(0deg,#026097 0,#71C6F7 );
border-radius: 20px;
z-index: 5;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
text-align: center;
color: #ffffff;
overflow: hidden;
padding: 0;
cursor: pointer;
box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.5) 0px 18px 36px -18px inset;
margin-top: 0;

}

.card_carousel h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  font-weight: 800;
  pointer-events: none;
  opacity: 0.1;
}
/*
 span {
  top: 75%;
} 
*/
.card_carousel .content h3 {
  font-size: 3rem;
  padding-bottom: 10px;
}
.card_carousel .content p {
  font-size: 1.6rem;
  line-height: 25px;
}
.card_carousel .content {
  transform: translateY(100%);
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.card_carousel:hover .content {
  transform: translateY(0);
  opacity: 1;
}

/* .card .card__add {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1);
} */

 .card__rating {
  opacity: 1;
transform: scale(1);
height: 25px;
margin-right: 125px;
/* right: 113px; */
width: 40%;
top: 0px;
}
@media (max-width: 767px) {
    .card__rating {
        margin-right: 110px;
    }
}
@media (max-width: 991px) {
    .card__rating {
        margin-right: 120px;
    }
}
@media (max-width: 575px) {
    .card__rating {
        margin-right: 180px;
    }
}
@media (max-width: 414px) {
    .card__rating {
        margin-right: 117px;
    }
}
@media (max-width: 360px) {
    .card__rating {
        margin-right: 95px;
    }
}
</style>

<style scoped>

 /* .cardBox {
  width: auto;
height: 26vh;
position: relative;
display: grid;
place-items: center;
overflow: hidden;
border-radius: 20px;
box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 10px 0px, rgba(0, 0, 0, 0.5) 0px 2px 15px 0px;
margin-bottom: 1vh;
margin-top: 1vh;
}
.card {
  position: absolute;
width: 100%;
height: 100%;
background-image: linear-gradient(0deg,#026097 0,#71C6F7 );
border-radius: 20px;
z-index: 5;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
text-align: center;
color: #ffffff;
overflow: hidden;
padding: 0;
cursor: pointer;
box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.5) 0px 18px 36px -18px inset;
margin-top: 0;

}

.card h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  font-weight: 800;
  pointer-events: none;
  opacity: 0.1;
} */
/*
 span {
  top: 75%;
} 
*/
/* .card .content h3 {
  font-size: 3rem;
  padding-bottom: 10px;
}
.card .content p {
  font-size: 1.6rem;
  line-height: 25px;
}
.card .content {
  transform: translateY(100%);
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.card:hover .content {
  transform: translateY(0);
  opacity: 1;
}

.cardBox::before {
  content: "";
position: absolute;
width: 40%;
height: 150%;
background: #40E0D0;
background-image: linear-gradient(0deg,#026097 0,#71C6F7 );
-webkit-animation: glowing01 200s linear infinite;
animation: glowing01 200s linear infinite;
transform-origin: center;
-webkit-animation: glowing 200s linear infinite;
animation: glowing 50s linear infinite;
}


.cardBox2 {
  width: auto;
height: 26vh;
position: relative;
display: grid;
place-items: center;
overflow: hidden;
border-radius: 20px;
box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 10px 0px, rgba(0, 0, 0, 0.5) 0px 2px 15px 0px;
margin-bottom: 1vh;
margin-top: 1vh;
}
.cardBox2::before {
  content: "";
  position: absolute;
  width: 140%;
  height: 70%;
  background: #40E0D0;
background: -webkit-linear-gradient(to right, #0E574E, #00b3b3, #1affff);
background: linear-gradient(to right, #0E574E, #00b3b3, #1affff);
  animation: glowing01 200s linear infinite;
  transform-origin: center;
  animation: glowing 200s linear infinite;
} */
.card2 {
  position: absolute;
  width: 95%;
  height: 92%;
  background: #fff;
  border-radius: 20px;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #ffffff;
  overflow: hidden;
  padding: 0;
  cursor: pointer;
  /* box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.5) 0px 18px 36px -18px inset; */
    margin-top: 0;
}

.card2 h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  font-weight: 800;
  pointer-events: none;
  opacity: 0.1;
}
/*
 span {
  top: 75%;
} 
*/
.card2 .content h3 {
  font-size: 3rem;
  padding-bottom: 10px;
}
.card2 .content p {
  font-size: 1.6rem;
  line-height: 25px;
}
.card2 .content {
  transform: translateY(100%);
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.card2:hover .content {
  transform: translateY(0);
  opacity: 1;
}




@keyframes glowing {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}


.pointer {
  cursor: pointer;
}
@import url(https://fonts.googleapis.com/css?family=Droid+Serif:400,700);
@import url(https://fonts.googleapis.com/css?family=Raleway:100,400,700);
/* Component Needs */
.pc-tab > input,
.pc-tab section > div {
  display: none;
}
#tab0:checked ~ section .tab0,
#tab1:checked ~ section .tab1,
#tab2:checked ~ section .tab2,
#tab3:checked ~ section .tab3,
#tab4:checked ~ section .tab4,
#tab5:checked ~ section .tab5,
#tab6:checked ~ section .tab6 {
  display: block;
}
#tab0:checked ~ nav .tab0,
#tab1:checked ~ nav .tab1,
#tab2:checked ~ nav .tab2,
#tab3:checked ~ nav .tab3,
#tab4:checked ~ section .tab4,
#tab5:checked ~ section .tab5,
#tab6:checked ~ section .tab6 {
  color: red;
}
/* Visual Styles */
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

h1 {
  text-align: center;
  font-weight: 100;
  font-size: 60px;
  color: #e74c3c;
}
.pc-tab {
  width: 100%;
  max-width: 100%;
  height: fit-content;
  margin: 0 auto;
}
.pc-tab ul {
  list-style: none;
justify-content: center;
height: -webkit-fit-content;
height: -moz-fit-content;
height: fit-content;
padding-right: 0%;
padding-left: 0%;
}
.pc-tab ul li label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Noto Kufi Arabic', sans-serif;
  color: #fff;
  background-image: linear-gradient(0deg,#085786,#71c6f7);
  margin-bottom: 0;
  cursor: pointer;
  border-radius: 10px;
  margin: 0 5px;
  position: relative;
  z-index: 100;
  transition: color 0.5s;
}
.pc-tab ul li label:hover {
  background-color: #026097;
}
.pc-tab ul li label:active {
  background-color: #52cbff;
}
.pc-tab ul li:not(:last-child) label {
  border-right-width: 0;
}
.pc-tab section {
  font-family: "Droid Serif";
  clear: both;
}

.pc-tab section div h2 {
  margin: 0;
  font-family: "Raleway";
  letter-spacing: 1px;
  color: #34495e;
}
#tab0:checked ~ nav .tab0 label,
#tab1:checked ~ nav .tab1 label,
#tab2:checked ~ nav .tab2 label,
#tab3:checked ~ nav .tab3 label,
#tab4:checked ~ nav .tab4 label,
#tab5:checked ~ nav .tab5 label,
#tab6:checked ~ nav .tab6 label {
  color: #085786;
background: #71c6f7;
position: relative;
}
#tab0:checked ~ nav .tab0 label:after,
#tab1:checked ~ nav .tab1 label:after,
#tab2:checked ~ nav .tab2 label:after,
#tab3:checked ~ nav .tab3 label:after,
#tab4:checked ~ nav .tab4 label:after,
#tab5:checked ~ nav .tab5 label:after,
#tab6:checked ~ nav .tab6 label:after {
  content: "";
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #fff;
  right: 0;
  bottom: -1px;
}
footer {
  margin-top: 50px;
  font-size: 14px;
  color: #ccc;
  text-align: center;
}
footer a {
  color: #aaa;
  text-decoration: none;
}
/* .card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    position: relative;
    overflow: hidden;
    background-image: linear-gradient(0deg,#315b10 0,#67bb27 );
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 15px 20px rgba(0,0,0,0.30), 0 15px 20px rgba(0,0,0,0.22);
} */
@media (max-width:540px) {
     .pc-tab ul {
        justify-content: center;
     }
 }




.section-title {
	padding-left: .5rem;
}

/* Ableton styles for slideable navigation with blurry effect */
.ableton {
	color: #ff764d
}

.ab__nav-slidder-wrapper {
	position: relative;
}

.ab__nav-slidder-wrapper::after {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 1.25rem;
	width: 3rem;
	background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff);
}

.ab__nav-slidder {
	overflow-x: auto;
	padding-bottom: 1.25rem;
}

.ab__nav-list {
	display: flex;
}

.ab__nav-list-item {
	padding: 0 .5rem;
	min-width: 17rem;
}

.ab__nav-list-title {
	margin-bottom: .5em;
}

/*==== End ====*/

  /* Youtube styles for slideable navigation with blurry effect */
.youtube {
	color: #ff0100;
}

.yt__filter-slider-wrapper {
	position: relative
}

.yt__filter-slider-wrapper::before,
.yt__filter-slider-wrapper::after {
	position: absolute;
	font-size: 1.5rem;
	width: 8rem;
}


.yt__filter-slider {
	overflow-x: auto;
	padding-bottom: 1.25rem
}

.yt__filter-list {
	display: flex;
}

.yt__filter-list-item {
	padding: .7em .8em;
	background-color: #0000000d;
	border: 1px solid #0000001a;
	border-radius: 1.5rem;
  color: #595555;
}

.yt__filter-list-item:first-child {
	margin-right: .4rem;
}

.yt__filter-list-item + .yt__filter-list-item {
	margin: 0 .4rem;
}

.yt__filter-list-item:hover {
	cursor: pointer;
	background-color: #0000001a;
}

.active {
	background-color: #606060;
	color: #fff;
}

.active:hover {
	background-color: #909090;
}

h2 {
		font-size: 5em;
		text-transform: uppercase;
		line-height: 1.5em;
		color: #fff;
		
	}
	p {
		font-size: 1.3em;
		line-height: 1.3em;
		border-top: 2px #fff solid;
		width: 33%;
		min-width: 25rem;
		margin: 0rem auto;
		padding: 1.5rem 0;
	}
	






</style>
<style lang="scss">

.fnc {

// main variables, basically everything depends on $slidingAT
$slidingAT: 1s;
$slidingDelay: $slidingAT / 3 * 2;
$maskAT: $slidingAT*0.7;
$headingAT: $slidingAT * 1.5;
$controlAT: $slidingAT/2;
$easing: ease-in-out;
$blendMode: luminosity;

$contentLeft: 0%;
$contentTop: 10%;

$headingPadding: 30px;
$headingFontSize: 1em;

$maskTop: 15%;
$maskRight: 30%;
$maskHeight: 36vh;
$maskWidth: $maskHeight * 0.75;

$black: #1F2833;

/* you can add color names and their values here
and then simply add classes like .m--blend-$colorName to .fnc-slide 
to apply specific color for mask blend mode */
$bgColors: (
  dark: lighten(#B0B0B06B, 20%),
  green: #B0B0B06B,
  red: lighten(#B0B0B06B, 10%),
  blue: #B0B0B06B
);

&-slider {
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  height: 50vh;

  *, *:before, *:after {
    box-sizing: border-box;
  }

  &__slides {
    position: relative;
    height: 50vh;
    transition: transform $slidingAT $slidingDelay;
  }
  
  // iterate through color map and set colors for specific classes
  @each $colorTheme, $bgColor in $bgColors {
    .m--blend-#{$colorTheme} {
      .fnc-slide__inner {
        background-color: lighten($bgColor, 20%);
      }

      .fnc-slide__mask-inner {
        background-color: $bgColor;
      }
    }

    .m--navbg-#{$colorTheme} {
      background-color: $bgColor;
    }
  }
}

&-slide {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translate3d(0,0,0);

  &.m--before-sliding {
    z-index: 2 !important;
    transform: translate3d(100%,0,0);
  }

  &.m--active-slide {
    z-index: 1;
    transition: transform $slidingAT $slidingDelay $easing;
    transform: translate3d(0,0,0);
  }

  &__inner {
    position: relative;
    height: 50vh;
    background-size: cover;
    background-position: center top;
    transform: translate3d(0,0,0);

    .m--global-blending-active &,
    .m--blend-bg-active & {
      background-blend-mode: $blendMode;
    }

    .m--before-sliding & {
      transform: translate3d(-100%,0,0);
    }

    .m--active-slide & {
      transition: transform $slidingAT $slidingDelay $easing;
      transform: translate3d(0,0,0);
    }
  }
  &__slideimage{
    
  position: relative;
right: 0;
left: 25%;
top: 10%;
max-height: 45vh;

  }
  &__mask {
    $activeDelay: $slidingAT + $slidingDelay/3;
    $xOffset: 200px;
    $yOffset: 0;
    $deg: 10deg;

    overflow: hidden;
    z-index: 1;
    position: absolute;
    right: $maskRight;
    top: $maskTop;
    width: $maskWidth;
    height: $maskHeight;
    margin-right: $headingPadding * -3;
    //polygon(95% 8%, 77% 10%, 54% 30%, 25% 9%, 7% 8%, 7% 96%, 25% 96%, 25% 31%, 53% 53%, 77% 33%, 77% 97%, 95% 97%)
    // This line basically does all magic with masking effect. Very simple and elegant. Webkit only atm.
    -webkit-clip-path: polygon(7% 8%, 8% 91%, 23% 91%, 22% 21%, 77% 22%, 77% 78%, 21% 78%, 20% 91%, 91% 91%, 90% 8%);
    clip-path: polygon(7% 8%, 8% 91%, 23% 91%, 22% 21%, 77% 22%, 77% 78%, 21% 78%, 20% 91%, 91% 91%, 90% 8%);
    transform-origin: 50% 0;
    transition-timing-function: $easing;

    .m--before-sliding & {
      transform: rotate($deg*-1) translate3d($xOffset,0,0);
      opacity: 0;
    }

    .m--active-slide & {
      transition: transform $maskAT $activeDelay, opacity $maskAT/2 $activeDelay;
      transform: translate3d(0,0,0);
      opacity: 1;
    }

    .m--previous-slide & {
      transition: transform $maskAT $slidingDelay/2, opacity $maskAT/2 $maskAT/2 + $slidingDelay/2;
      transform: rotate($deg) translate3d($xOffset*-1,0,0);
      opacity: 0;
    }

    &-inner {
      z-index: -1;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100vw;
      height: 50vh;
      margin-left: -50vw;
      margin-top: -15vh;
      background-size: cover;
      background-position: center center;
      background-blend-mode: $blendMode;
      transform-origin: 50% (100vh - $maskHeight) / 2;
      transition-timing-function: $easing;

      .m--before-sliding & {
        transform: translateY($yOffset) rotate($deg) translateX($xOffset*-1) translateZ(0);
      }

      .m--active-slide & {
        transition: transform $maskAT $activeDelay;
        transform: translateX(0);
      }

      .m--previous-slide & {
        transition: transform $maskAT $slidingDelay/2;
        transform: translateY($yOffset) rotate($deg*-1) translateX($xOffset) translateZ(0);
      }
    }
  }

  &__content {
    z-index: 2;
    position: absolute;
    left: $contentLeft;
    top: $contentTop;
    text-align: right;
  }

  &__heading {
    margin-bottom: 10px;
    text-transform: uppercase;

    &-line {
      overflow: hidden;
      position: relative;
      padding-right: 20px;
      font-size: $headingFontSize;
      color: #fff;
      word-spacing: 10px;

      &:nth-child(2) {
        padding-left: $headingPadding;
      }

      .m--before-sliding & {
        transform: translateY(100%);
      }

      .m--active-slide & {
        transition: transform $headingAT $slidingDelay*1.5;
        transform: translateY(0);
      }

      .m--previous-slide & {
        transition: transform $headingAT;
        transform: translateY(-100%);
      }

      span {
        display: block;

        .m--before-sliding & {
          transform: translateY(-100%);
        }

        .m--active-slide & {
          transition: transform $headingAT $slidingDelay*1.5;
          transform: translateY(0);
        }

        .m--previous-slide & {
          transition: transform $headingAT;
          transform: translateY(100%);
        }
      }
    }
  }

  &__action-btn {
    $hoverAT: 0.3s;
    $fontSize: 18px;
    $vertPadding: 10px;
    $lineHeight: $fontSize + $vertPadding*2;

    position: relative;
    margin-left: 5px;
    padding: $vertPadding 15px;
    font-size: $fontSize;
    line-height: 1;
    float: left;
    color: transparent;
    border: none;
    text-transform: uppercase;
    background: transparent;
    cursor: pointer;
    text-align: center;
    outline: none;

    span {
      position: absolute;
      left: 10px;
      top: 0;
      width: 100%;
      height: 100%;
      perspective: 1000px;
      transform-style: preserve-3d;
      transition: transform $hoverAT;
      transform-origin: 50% 0;
      line-height: $lineHeight;
      color: #fff;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: 2px solid #fff;
        border-top: none;
        border-bottom: none;
      }

      &:after {
        content: attr(data-text);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        line-height: $lineHeight;
        background: $black;
        opacity: 0;
        transform-origin: 50% 0;
        transform: translateY(100%) rotateX(-90deg);
        transition: opacity $hoverAT/2 $hoverAT/2;
      }
    }

    &:hover span {
      transform: rotateX(90deg);

      &:after {
        opacity: 1;
        transition: opacity $hoverAT/2;
      }
    }
  }
}

&-nav {
  z-index: 5;
  position: absolute;
  right: 0;
  bottom: 0;

  &__bgs {
    z-index: -1;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    &.m--nav-bg-before {
      z-index: 2 !important;
      transform: translateX(100%);
    }

    &.m--active-nav-bg {
      z-index: 1;
      transition: transform $slidingAT $slidingDelay;
      transform: translateX(0);
    }
  }
  
  &__controls {
    font-size: 0;
  }

  &__control {
    $controlWidth: 100px;

    overflow: hidden;
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: $controlWidth;
    height: 50px;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    transition: background-color $controlAT;

    &.m--active-control {
      background: $black;
    }

    &-progress {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: #fff;
      transform-origin: 0 50%;
      transform: scaleX(0);
      transition-timing-function: linear !important;

      .m--with-autosliding .m--active-control & {
        transform: scaleX(1);
      }

      .m--prev-control & {
        transform: translateX(100%);
        transition: transform 0.5s !important;
      }

      .m--reset-progress & {
        transform: scaleX(0);
        transition: transform 0s 0s !important;
      }

      .m--autosliding-blocked & {
        transition: all 0s 0s !important;
        transform: scaleX(0) !important;
      }
    }
  }
}

}


/* NOT PART OF COMMON SLIDER STYLES */
$font: 'Noto Kufi Arabic', sans-serif;
$creditsAT: 0.7s;
$creditsWidth: 400px;

body {
margin: 0;
}

.demo-cont {
overflow: hidden;
position: relative;
height: 50vh;
perspective: 1500px;
background: #000;

&__credits {
  box-sizing: border-box;
  overflow-y: auto;
  z-index: 1;
  position: absolute;
  right: 0;
  top: 0;
  width: $creditsWidth;
  height: 100%;
  padding: 20px 10px 30px;
  background: #303030;
  font-family: $font;
  color: #fff;
  text-align: center;
  transition: transform $creditsAT;
  transform: translate3d(100%,0,0) rotateY(-45deg);
  will-change: transform;
  
  .credits-active & {
    transition: transform $creditsAT $creditsAT/3;
    transform: translate3d(0,0,0);
  }
  
  *, *:before, *:after {
    box-sizing: border-box;
  }
  
  &-close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 28px;
    height: 28px;
    cursor: pointer;
    
    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 2px;
      margin-top: -1px;
      background: #fff;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
  
  &-heading {
    text-transform: uppercase;
    font-size: 40px;
    margin-bottom: 20px;
  }
  
  &-img {
    display: block;
    width: 60%;
    margin: 0 auto 30px;
    border-radius: 10px;
  }
  
  &-name {
    margin-bottom: 20px;
    font-size: 30px;
  }
  
  &-link {
    display: block;
    margin-bottom: 10px;
    font-size: 24px;
    color: #fff;
  }
  
  &-blend {
    font-size: 30px;
    margin-bottom: 10px;
  }
}
}

.example-slider {
z-index: 2;
transform: translate3d(0,0,0);
transition: transform $creditsAT;

.credits-active & {
  transform: translate3d($creditsWidth*-1,0,0) rotateY(10deg) scale(0.9);
}

.fnc-slide {
  
  &-1 {
    .fnc-slide__inner,
    .fnc-slide__mask-inner {
      background-image: url('../../assets/liquid-cheese.svg');
    }
  }
  &-2 {
    .fnc-slide__inner,
    .fnc-slide__mask-inner {
      background-image: url('../../assets/liquid-cheese.svg');
    }
  }
  &-3 {
    .fnc-slide__inner,
    .fnc-slide__mask-inner {
      background-image: url('../../assets/liquid-cheese.svg');
    }

    .fnc-slide__inner:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(255,255,255,0.1);
    }
  }
  &-4 {
    .fnc-slide__inner,
    .fnc-slide__mask-inner {
      background-image: url('../../assets/liquid-cheese.svg');
    }

    .fnc-slide__inner:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.2);
    }
  }
}

.fnc-slide__heading,
.fnc-slide__action-btn,
.fnc-nav__control {
  font-family: $font;
}
}

/* COLORFUL SWITCH STYLES 
 ORIGINAL DEMO - https://codepen.io/suez/pen/WQjwOb */
$w: 180px;
$_baseW: 280;
$h: $w * 120 / $_baseW;
$br: $w * 50 / $_baseW;
$outerOffset: 5px;
$animTime: 0.5s;
$dotS: $w * 8 / $_baseW;
$dotLeft: $w * 204 / $_baseW;
$dotLeftOffset: $w * -125 / $_baseW;
$offS: $w * 64 / $_baseW;
$onLeft: $w * 162 / $_baseW;
$onTop: $w * 35 / $_baseW;
$onWidth: $w * 30 / $_baseW;
$onHeight: $w * 56 / $_baseW;
$cubIn: cubic-bezier(.52,-0.96,.51,1.28);
$cubOut: cubic-bezier(.67,-0.16,.47,1.61);

@mixin switchOn() {
.colorful-switch__checkbox:checked ~ .colorful-switch__label & {
  @content;
}
}

.colorful-switch {
position: relative;
width: $w;
height: $h;
margin: 0 auto;
border-radius: $br;
background: #cfcfcf;

&:before {
  content: "";
  z-index: -1;
  position: absolute;
  left: -$outerOffset;
  top: -$outerOffset;
  width: $w + $outerOffset*2;
  height: $h + $outerOffset*2;
  border-radius: $br + $outerOffset;
  background: #314239;
  transition: background-color 0.3s;
}

&:hover:before {
  background: #4C735F;
}

&__checkbox {
  z-index: -10;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

&__label {
  z-index: 1;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: $br;
  cursor: pointer;
}

&__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: $w * 3;
  height: 100%;
  background: linear-gradient(90deg, #14DCD6 0, #10E7BD $w, #EF9C29 $w*2, #E76339 100%);
  transition: transform $animTime;
  transform: translate3d($w*-2,0,0);

  @include switchOn {
    transform: translate3d(0,0,0);
  }
}

&__dot {
  position: absolute;
  left: $dotLeft;
  top: 50%;
  width: $dotS;
  height: $dotS;
  margin-left: $dotS/-2;
  margin-top: $dotS/-2;
  border-radius: 50%;
  background: #fff;
  transition: transform $animTime;
  transform: translate3d(0,0,0);

  @include switchOn {
    transform: translate3d($dotLeftOffset,0,0);
  }
}

&__on {
  position: absolute;
  left: $onLeft;
  top: $onTop;
  width: $onWidth;
  height: $onHeight;
  transition: transform $animTime;
  transform: translate3d(0,0,0);

  @include switchOn {
    transform: translate3d($dotLeftOffset,0,0);
  }

  &__inner {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: transform $animTime/2 0s $cubIn;
    transform-origin: 100% 50%;
    transform: rotate(45deg) scale(0) translateZ(0);

    @include switchOn {
      transition: transform $animTime/2 $animTime/2 $cubOut;
      transform: rotate(45deg) scale(1) translateZ(0);
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      border-radius: $dotS/2;
      background: #fff;
    }
    &:before {
      left: 0;
      bottom: 0;
      width: 100%;
      height: $dotS+1px;
    }
    &:after {
      right: 0;
      top: 0;
      width: $dotS+1px;
      height: 100%;
    }
  }
}

&__off {
  position: absolute;
  left: $dotLeft;
  top: 50%;
  width: $offS;
  height: $offS;
  margin-left: $offS/-2;
  margin-top: $offS/-2;
  transition: transform $animTime;
  transform: translate3d(0,0,0);

  @include switchOn {
    transform: translate3d($dotLeftOffset,0,0);
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: $dotS;
    margin-top: $dotS/-2;
    border-radius: $dotS/2;
    background: #fff;
    transition: transform $animTime/2 $animTime/2;

    @include switchOn {
      transition-delay: 0s;
    }
  }
  &:before {
    transform: rotate(45deg) scaleX(1) translateZ(0);

    @include switchOn {
      transform: rotate(45deg) scaleX(0) translateZ(0);
    }
  }
  &:after {
    transition-timing-function: $cubOut;
    transform: rotate(-45deg) scaleX(1) translateZ(0);

    @include switchOn {
      transition-timing-function: ease;
      transform: rotate(-45deg) scaleX(0) translateZ(0);
    }
  }
}
}
</style>
<style scoped>

.img2 {
  height: auto;
  width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
#busicard {
	position: relative;
  background: linear-gradient(to left, #00D2C8, #179C8C);
  border-radius: 35px;
  height: 232px;
  max-width: 440px;
  margin: 10px auto;
}
#busicard h1 {
	color: #FAEAFF;
}
.movembar {
  display: flex;
    flex-direction: row;
    justify-content: baseline;
    align-items: flex-start;
}
.movegear {
  display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  margin-top: -2px;
}
.mbar {
  width: 10px;
  height: 3px;
  background: #FAEAFF;
  position: absolute;
  margin: 17px 35px 0 36px;
}
.mbar:before {
  content: '';
  width: 12px;
  height: 3px;
  background: #FAEAFF;
  position: absolute;
  top: 0px;
  left: 0;
}
.mbar:after {
  content: '';
  width: 18px;
  height: 3px;
  background: #FAEAFF;
  position: absolute;
  top: 5px;
  left: 0;
}
.mbut:hover .mbar, .mbut:hover .mbar:before, .mbut:hover .mbar:after {
  background: #2F4858;
}
.user {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
-webkit-user-select: none;
   -moz-user-select: none;
    -ms-user-select: none;
        user-select: none;
        margin-top: -24px;
}
.user img {
  border-radius: 35px 35px 0 0;
width: 100%;
/* border: 4px solid #FAEAFF; */
/* margin-top: 1vh; */
height: 130px;
}
.user img:hover{
  filter: saturate(0);
-webkit-filter: saturate(0);
-moz-filter: saturate(0);
}
.gear {
  background-image:
    radial-gradient(at center center, #00D2C8 23%, #eee 25%, #FAEAFF 57%, transparent 53%),
    linear-gradient(0deg, transparent 43%, #eee 40%, #FAEAFF 60%, transparent 60%),
    linear-gradient(45deg, transparent 43%, #eee 43%, #FAEAFF 57%, transparent 57%),
    linear-gradient(90deg, transparent 40%, #eee 40%, #FAEAFF 60%, transparent 60%),
    linear-gradient(135deg, transparent 43%, #eee 43%, #FAEAFF 57%, transparent 57%);
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin: 17px 35px 0 36px;
}
.gear:hover {
  background-image:
    radial-gradient(at center center, #00D2C8 23%, #242424 25%, #2F4858 57%, transparent 53%),
    linear-gradient(0deg, transparent 43%, #242424 40%, #2F4858 60%, transparent 60%),
    linear-gradient(45deg, transparent 43%, #242424 43%, #2F4858 57%, transparent 57%),
    linear-gradient(90deg, transparent 40%, #242424 40%, #2F4858 60%, transparent 60%),
    linear-gradient(135deg, transparent 43%, #242424 43%, #2F4858 57%, transparent 57%);
}
.info li {
  color: #eee;
  font-size: 0.8em;
  display: inline-block;
  margin: 0 6px;
}
.hrtop {
  display: inline-block;
  background-color: #FAEAFF;
  width: 2px;
  height: 22px;
  margin: -2px auto;
}
.contacts {
  min-height: 100px;
}
.contacts i {
  display: inline-block;
  margin: 20px 0;
}
.contacts a {
  margin: 0 6px;
}
.bfollow {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  /*! margin: 25px 0 0 0; */
}
.bfollow a {
  position: absolute;
  box-shadow: 0 0 15px 1px #000;
  color: #FAEAFF;
  background-color: #1F937D;
  border-radius: 30px;
  padding: 7px 49px;
  transition: 350ms;
    -webkit-transition: all .3s;
}
.bfollow a:hover {
  font-weight: bold;
  color: #2F4858;
  background-color: #FAEAFF;
  padding: 8px 56px;
  transition: 350ms;
    -webkit-transition: all .3s;
}
.content {
  color: #FAEAFF;
  background: linear-gradient(to bottom, #2F4858, #2C687C);
  border-radius: 0 0 30px 30px;
  min-height: 85px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content p {
  min-width: 100%;
border-top: none;
text-align: center;
font-family: "Noto Kufi Arabic";
font-size: 16px;
}
.lock {
  width: 26px;
  height: 16px;
  background: #FAEAFF;
  position: relative;
  border-radius: 4px;
  top: 30px;
}
.lock:before {
  content: '';
  width: 6px;
  height: 6px;
  background: transparent;
  border: 6px solid #FAEAFF;
  border-bottom: 0;
  border-radius: 6px 6px 0 0;
  position: absolute;
  bottom: 16px;
  right: 4px;
}
a .fa {
  font-size: 36px;
  transition: 500ms;
}
a:hover .fa {
  color: #2F4858;
  transition: 500ms;
}
a .fa-twitter-square, a .fa-instagram, a .fa-facebook-square {
  color: #FAEAFF;
}

.texto{
  margin-right: 15px;
text-align: right;
font-size: 15px;
background: #1F937D;
border-radius: 0 10px 10px 15px;
padding: 0 16px;
z-index: 9;
font-weight: 900;
}


</style>


